<template>
	<div>
		<div>
			<el-input
				v-model="cookie"
				autosize
				type="textarea"
				placeholder="请输入Cookie"
			/>
		</div>
		<div style="margin-top: 2%">
			<el-button type="primary" @click="syncData()">同步数据</el-button>
		</div>

		<!--	上传表格同步日进尺-->
		<div style="margin-top: 10%">
			<el-date-picker
				v-model="date"
				type="date"
				placeholder="请选择日报时间"
				format="YYYY-MM-DD"
				value-format="YYYY-MM-DD"
				style="padding-bottom: 2%"
			/>
			<el-upload
				ref="upload"
				class="upload-demo"
				action="https://www.cr12cz.cn/czscsms/up/upload"
				:auto-upload="false"
				:data="dataup"
				:on-change="imgchange"
				:on-remove="handleRemove"
				:multiple="false"
				:limit="1"
			>
				<template #trigger>
					<el-button type="primary">请选择日进度表</el-button>
				</template>
				<div style="margin-top: 2%">
					<el-button
						style="width: 480px"
						type="success"
						@click="submitUpload"
					>上传
					</el-button>
				</div>
			</el-upload>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowDateYear} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			cookie: '',
			fileList: [],
			dataup: {
				file: '',
				userid: ''
			},
			date: ''
		}
	},
	created() {
	},
	methods: {
		syncData() {
			this.axios.post('/bigScreenShow/syncData', (response) => {
				if (response.obj) {
					ElMessage.success('数据同步成功!')
					this.cookie = ''
				}
			}, {
				cookie: this.cookie,
				year: setNowDateYear(new Date())
			})
		},
		submitUpload() {
			this.dataup.file = this.fileList[0].raw;
			this.axios.post('/daySchedule/uploadSchedule', (response) =>{
				if (response.obj){
					ElMessage({
						message: '导入成功!',
						type: 'success'
					})
					this.reload()
				}
			},{
				file: this.dataup.file,
				data_date: this.date
			})
		},
		imgchange(file) {
			this.fileList.push(file);
		}
		,
		handleRemove(file) {
			for (let i = 0; i < this.fileList.length; i++) {
				if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
					this.fileList.splice(i, 1)
				}
			}
		}
	}
}
</script>

<style>

</style>