<template>
	<div>
		<div>
			<el-select v-model="big_screen" class="m-2" placeholder="请选择预览大屏" size="small" style="width: 74%"
			           @change="goToUrl(this.big_screen)">
				<el-option
					v-for="item in big_screens"
					:key="item.showName"
					:label="item.showName"
					:value="item.urlPrefix"
				/>
			</el-select>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "Big_screen_preview",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER']),
		...mapGetters(['GET_TOKEN'])
	},
	data() {
		return {
			big_screens: [],
			big_screen: ''
		}
	},
	created() {
		this.getBigScreen()
	},
	methods: {
		getBigScreen(){
			this.axios.get('/bigScreenShow/getBigScreenUrl', (response)=> {
				this.big_screens = response.obj
			},{
				flag: 1
			})
		},
		goToUrl(val){
			const url = val + this.GET_TOKEN
			window.open(url, '_blank');
		}
	}
}
</script>

<style>

</style>